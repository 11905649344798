<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      om-heading.mt-1(h1) {{ $t('optInFeatures.label') }}
  .row(v-if="loading")
    .col
      LoadingTransition
  template(v-else)
    .row(v-for="feature in features" :key="feature.flag")
      .col-12
        .row
          h4.col-2.mb-1.align-self-center {{ getFlagName(feature.flag) }}
          om-switch#setFeatureFlag(:value="feature.active" @switch="setFeatureFlag(feature)")
        .row
          .col-8
            label.mb-1.color-dark-grey {{ feature.description }}
</template>

<script>
  import { mapState } from 'vuex';
  import GET_PUBLIC_FEATURES from '@/graphql/GetPublicFeatures.gql';

  export default {
    components: {
      LoadingTransition: () => import('@/components/LoadingTransition.vue'),
    },
    data() {
      return {
        features: [],
      };
    },
    computed: {
      ...mapState(['account']),
      loading() {
        return this.$apollo.queries.publicFeatures.loading;
      },
    },
    watch: {
      publicFeatures(newValue) {
        const helper = [];
        newValue.forEach((publicFeatureFlag) => {
          const { flag } = publicFeatureFlag;
          helper.push({
            flag,
            active: this.isExist(flag),
            description: publicFeatureFlag.description,
          });
        });
        this.features = helper;
      },
    },
    methods: {
      getFlagName(feature) {
        const normalizedName = feature.replace('_', ' ').toLowerCase();

        return `${normalizedName.charAt(0).toUpperCase()}${normalizedName.substr(1)}`;
      },
      setFeatureFlag(feature) {
        feature.active = !feature.active;

        const { flag, active } = feature;
        this.$store.dispatch('addOrRemoveFeatureFlagToUser', { flag, active });
      },
      isExist(flag) {
        return this.account.features.includes(flag);
      },
    },
    apollo: {
      publicFeatures: {
        query: GET_PUBLIC_FEATURES,
      },
    },
  };
</script>

<style lang="sass">
  @import '../../sass/variables/_colors.sass'
</style>
